/** ****** My Swiss Lunch v0.1 ****** */
/** ****** MSL Juin 2021      ****** */
/** *** ContactForm - Nous contacter **** */


<template>
  <div class="contactform">
  <div>
              <div class="divider is-centered">
                <h6>Contactez-nous !</h6>

              </div>
            </div>
<section>
    <div class="container">
    <br />
    Nous faisons de notre mieux pour vous satisfaire. Cependant, il se pourrait
    que vous rencontriez un problème, ou que vous souhaitiez nous poser une
    question ou suggérer une fonctionnalité. N'hésitez pas, ce formulaire est fait pour ça !

    <br />
    <br />
    Notez cependant que nous sommes une petite équipe, et que vous répondre nous
    prendra parfois un peu de temps ... !

    <br />
    <br />
    A très bientôt,
    <br />
    L'équipe My Swiss Lunch
    <br />
    <br />

    <section>
      <b-message v-if="errormessage" type="is-danger">
        {{ errormessage }}
      </b-message>

      <b-message v-if="successmessage" type="is-success">
        {{ successmessage }}
      </b-message>

      <b-field horizontal label="Nom">
        <b-input
          v-model="name"
          name="name"
          placeholder="Votre Nom"
          expanded
        ></b-input>
      </b-field>

      <b-field horizontal label="Email">
        <b-input
          v-model="email"
          name="email"
          type="email"
          placeholder="votre@email.com"
          expanded
        ></b-input>
      </b-field>

      <b-field horizontal label="">
        <b-checkbox
          v-model="checkbox_pro"
          expanded
        >Vous nous contactez en tant que professionnel de la restauration</b-checkbox>
      </b-field>

      <b-field horizontal label="Message">
        <b-input v-model="message" type="textarea"></b-input>
      </b-field>

      <b-field horizontal
        ><!-- Label left empty for spacing -->
        <vue-recaptcha
          @expired="onExpired"
          @verify="onVerifyCaptcha"
          sitekey="6LdTKDkbAAAAAAAlmmRGjGf3ozYJr98m3qkGgyxT"
          :loadRecaptchaScript="true"
        ></vue-recaptcha>
      </b-field>

      <b-field horizontal
        ><!-- Label left empty for spacing -->
        <p class="control">
          <b-button @click="sendForm" type="is-primary" v-if="formOKtoSend" :disabled='disableButton'
            >Envoyer !
          </b-button>
          <b-button
            @click="sendForm"
            type="is-primary"
            disabled
            v-if="!formOKtoSend"
            >Envoyer !
          </b-button>
        </p>
      </b-field>
    </section>
    </div>
    </section>
  </div>
</template>


<script>
import VueRecaptcha from "vue-recaptcha";
import {API} from "aws-amplify"
export default {
  name: "ContactForm",
  components: { VueRecaptcha },

  data() {
    return {
      email: "",
      message: "",
      name: "",
      captchaToken: "",
      captchaOK: false,
      errormessage: "",
      successmessage: "",
      checkbox_pro:false,
      disableButton:false,
    };
  },

  computed: {
    formOKtoSend() {
      if (this.captchaOK) {
        if (this.name != "") {
          if (this.message != "") {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(this.email).toLowerCase());
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    async onVerifyCaptcha(response) {
    
      this.captchaToken = response;
   //   const token = this.captchaToken;

            this.captchaOK = true;

            /*
try{


const sendData = {  queryStringParameters: { token : token } }
const data = await API.get('myswisslunchCaptchaAPI', '/captcha', sendData)

  if (data.success == true) {
            //console.log("captcha OK");
            this.captchaOK = true;
          } else {
            this.captchaOK = false;
            //this.$refs.recaptcha.reset() // Direct call reset method
            return;
          }
          this.isFetching = false;
}catch(e)
{
  console.log("fail", e);
}
     */

    },
    onExpired: function () {
      console.log("Expired");
    },
 async sendForm() {

      this.disableButton = true;

      if (this.captchaOK == true) {
        const name = this.name;
        const email = this.email;
        const message = this.message;
      var destinataire;
      if (this.checkbox_pro == true)
      {
        destinataire = "contactpro"
      }else{
        destinataire = "contact" 

      }

      const token = this.captchaToken;

try{
const sendData = {  
  
  queryStringParameters: { 
          senderName: name,
          senderEmail: email,
          message: message,
          sendTo: destinataire,
          token: token } }
const data = await API.get('myswisslunchSendEmailAPI', '/email', sendData);
console.log("data", data)

 this.successmessage = "Email envoyé avec succès, merci !";
            this.errormessage = "";
            this.email = "";
            this.name = "";
            this.message = "";
            this.disableButton = false;

}catch(e)
{
            this.errormessage =
              "Nous avons rencontré un problème, merci de réessayer";
            this.successmessage = "";
            this.disableButton = false;
            console.log("e", e);
}



      } else {
        this.errormessage =
          "Merci de valider le captcha pour prouver que vous n'êtes pas un robot !";
        this.successmessage = "";
      }
    },
  },
};
</script>
